const portfolio = [
    {
      banner: "/assets/portfolio/geyserWorx-banner.png",
      title_header: "Geyser",
      subTitle_header: "Worx",
      imgsrc: "/assets/portfolio/mainHouse.png",
      title: "Geyser Worx",
      sub_title: "All/mobile apps",
      category: ["all",  "android", "ios"],
      text: "GeyserWorx",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Now Have The Control In Your Hands",
      about_image : "/assets/portfolio/mainHouse.png",
      paragraph: "GeyserWorx® is a Patented (No. 2013/06616) micro-processor based product that utilises solar energy during the day to heat household or commercial water. This is done using PV (photovoltaic) panels reducing the electrical running costs of your geyser system.",
      button: "https://play.google.com/store/apps/details?id=com.sam.GeyserWorx&hl=en_IN&gl=US&pli=1",
      carousel_images: ["/assets/portfolio/geyserWorx-Controls.png","/assets/portfolio/geyserWorx-control.png","/assets/portfolio/geyserWorx-color.png ","/assets/portfolio/geyserWorx-temp.png"],
      last_title: "Geyser Worx",
      bold_paragraph: "This app allows you to manage your GeyserWorx unit's settings by connecting to its on board WiFi or over the internet,",
      last_paragraph: "GeyserWorx® is a Patented (No. 2013/06616) micro-processor based product that utilises solar energy during the day to heat household or commercial water. This is done using PV (photovoltaic) panels reducing the electrical running costs of your geyser system.This app allows you to manage your GeyserWorx unit's settings by connecting to its on board WiFi or over the internet, given that the unit is connected to a WiFi network with an internet connection.",
      last_image: "/assets/portfolio/temperature.png"
    },
    {
      banner: "/assets/portfolio/projects-banner.png",
      title_header: "Loudly - Volume",
      subTitle_header: "Booster",
      imgsrc: "/assets/portfolio/control.png",
      title: "Volume booster Louder sound",
      sub_title: "Android/Mobile Apps",
      category: ["all", "android"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Tune into better volumes with the new volume booster free app.",
      about_image : "/assets/portfolio/volumeControl.png",
      paragraph: "Tune into better volumes with the new volume booster free app. Volume booster “Loud Volume Booster for Speakers” works as a sound amplifier and headphone volume booster designed to boost volume by increasing loudness of video, audio or all notifications on Android.",
      button: "https://play.google.com/store/apps/details?id=feniksenia.app.speakerlouder90",
      carousel_images: ["/assets/portfolio/volumeControl.png","/assets/portfolio/volumeColor.png","/assets/portfolio/volumeBooster.png","/assets/portfolio/control.png"],
      last_title: "Volume Booster Louder Sound",
      bold_paragraph: "Tune into better volumes with the new volume booster free app.",
      last_paragraph: "Tune into better volumes with the new volume booster free app. Volume booster “Loud Volume Booster for Speakers” works as a sound amplifier and headphone volume booster designed to boost volume by increasing loudness of video, audio or all notifications on Android. With this Louder Volume sound Amplifier & Speaker Booster, you can enjoy the functionality of volume booster Equalizer as you perform editing and other tasks within a small floating bubble of the app.",
      last_image: "/assets/portfolio/volumeBooster.png"
    
    },
    {
      banner: "/assets/portfolio/vueLite-banner.png",
      title_header: "Vue",
      subTitle_header: "Lite",
      imgsrc: "/assets/portfolio/vueLite.png",
      title: "Vue Lite",
      sub_title: "Android/Mobile Apps",
      category: ["all", "android"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Vue is the world's first pair of everyday smart glasses.  ",
      about_image : "/assets/portfolio/vueLite-Wire.png",
      paragraph: "Listen to music, make phone calls, play podcasts and audiobooks, talk to your favorite voice assistants, and control playback on your phone, all with your glasses. Comes in prescription, sunglasses or non-corrective lenses.",
      button: "https://play.google.com/store/apps/details?id=com.vueglasses.app",
      carousel_images: ["/assets/portfolio/vueLite-Wireframes.png","/assets/portfolio/vueLite-Wire.png ","/assets/portfolio/vueLite-colors.png","/assets/portfolio/vueLites.png"],
      last_title: "Vue Lite",
      bold_paragraph: "Vue is the world's first pair of everyday smart glasses. Listen to music, make phone calls,",
      last_paragraph: "Vue is the world's first pair of everyday smart glasses. Listen to music, make phone calls, play podcasts and audiobooks, talk to your favorite voice assistants, and control playback on your phone, all with your glasses. Comes in prescription, sunglasses or non-corrective lenses. With the Vue Lite app, you can talk to Alexa straight from your glasses. Check the weather, locate your nearest coffee shop, control your smart home lighting, or add items to your to-do list, all through Alexa. Support for other voice control",
      last_image: "/assets/portfolio/vueLite-Wireframes.png"
    
    },
    {
      banner: "/assets/portfolio/tuMeri-banner.png",
      title_header: "Tumeri",
      subTitle_header: "Website",
      imgsrc: "/assets/portfolio/tuMeri.png",
      title: "Website",
      sub_title: "Ios/Mobile Apps",
      category: ["all","websites"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      about_image : "/assets/portfolio/tuMeri-image.png",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      button: "",
      carousel_images: ["/assets/portfolio/tuMeri.png","/assets/portfolio/tuMeri-image.png","/assets/portfolio/tuMeri-Hydrate.png","/assets/portfolio/tuMeri-Grid.png"],
      last_title: "Lorem Ipsum",
      bold_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua.",
      last_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      last_image: "/assets/portfolio/tuMeri-Hydrate.png"
    
    },
    
    {
      banner: "/assets/portfolio/libaStyle-banner.png",
      title_header: "Liba",
      subTitle_header: "Style",
      imgsrc: "/assets/portfolio/bestChoice.png",
      title: "The Best Choice",
      sub_title: "Android/Mobile Apps",
      category: ["all","websites"],
       text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      about_image : "/assets/portfolio/bestChoice-image.png",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      button: "",
      carousel_images: ["/assets/portfolio/bestChoice-grid.png","/assets/portfolio/bestChoice-image.png","/assets/portfolio/bestChoice.png ","/assets/portfolio/bestChoice-colors.png "],
      last_title: "Lorem Ipsum",
      bold_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      last_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      last_image: "/assets/portfolio/bestChoice-grid.png"
   
      
    },
    {
      banner: "/assets/portfolio/lucci-banner.png",
      title_header: "Lucci",
      subTitle_header: "Essentials",
      imgsrc: "/assets/portfolio/loremIpsum.png",
      title: "Lucci Essentials", 
      sub_title: "Websites/Mobile Apps",
      category: ["all","websites"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      about_image : "/assets/portfolio/lucci-image.png",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      button: "",
      carousel_images:["/assets/portfolio/lucci-Grid.png","/assets/portfolio/lucci-image.png","/assets/portfolio/lucciColors.png","/assets/portfolio/loremIpsum.png"],
      last_title: "Lorem Ipsum",
      bold_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      last_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat.",
      last_image: "/assets/portfolio/lucci-Grid.png"
    
    },
    {
      banner: "/assets/portfolio/screenCast-banner.png",
      title_header: "Screen",
      subTitle_header: "Cast",
      imgsrc: "/assets/portfolio/nowCast.png",
      title: "Now Cast Anything Anytime",
      sub_title: "Ios/Mobile Apps",
      category: ["all","ios"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      about_image : "/assets/portfolio/layoutSystem.png",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Lorem ipsum dolor sit amet,",
      
      button: "",
      carousel_images: ["/assets/portfolio/castLogos.png ","/assets/portfolio/nowCast.png","/assets/portfolio/mockupsCast.png","/assets/portfolio/layoutSystem.png"],
      last_title: "Lorem Ipsum",
      bold_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      last_paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. ",
      last_image: "/assets/portfolio/mockupsCast.png"
    
    },
    
    {
      banner: "/assets/portfolio/chatTranslator-banner.png",
      title_header: "Chat",
      subTitle_header: "Translator",
      imgsrc: "/assets/portfolio/chatTranslator.png",
      title: "Chat Transaltor",
      sub_title: "Android/Mobile Apps",
      category: ["all","android"],
      text: "loudly - Volume Booster",
      shortdesc: "We Promise That Recruiting The Perfect ",
      about_paragraph: "Chat Translator Keyboard – All language Translator is the simplest photo translator. ",
      about_image : "/assets/portfolio/chatTranslate-image.png",
      paragraph: "Chat Translator Keyboard – All language Translator is the simplest photo translator, OCR scanner, language dictionary, and translator app you will find to chat and text in any language you like.",
      
      button: "https://play.google.com/store/apps/details?id=com.globalkeyboard.typing.chat.translator.languages", 
      carousel_images: ["/assets/portfolio/chatTranslate-color.png","/assets/portfolio/chatLayout.png","/assets/portfolio/chatTranslate-image.png","/assets/portfolio/chatTranslator.png"],
      last_title: "Chat Translator",
      bold_paragraph: "Chat Translator Keyboard – All language Translator is the simplest photo translator.",
      last_paragraph: "Chat Translator Keyboard – All language Translator is the simplest photo translator, OCR scanner, language dictionary, and translator app you will find to chat and text in any language you like.",
      last_image: "/assets/portfolio/chatLayout.png"
    
    },
  
  ];
export {portfolio};
