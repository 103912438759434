import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from 'react-loader-spinner';

toast.configure();

function Homecontact() {
  const [input, setInput] = useState({
    name: "",
    email: "",
    message: "",
  });
  const { name, email, message } = input;
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setErrors(handleValidate(input));
  }, [input]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const validationErrors = handleValidate(input);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios.post('https://ums.ultivic.com/api/development/contact-form', input, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            toast.success("Success! We will contact you soon", { closeOnClick: true });
            setInput({ name: "", email: "", message: "" });
            setSubmitted(false);
          }
        })
        .catch(error => {
          setLoading(false);
          toast.error("An error occurred. Please try again later.");
        });
    }
  };

  const onChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleValidate = (input) => {
    let errors = {};
    if (!input.email) {
      errors.email = "Please enter email address.";
    } else {
      let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!pattern.test(input.email)) {
        errors.email = "Please enter a valid email address.";
      }
    }
    if (!input.name) {
      errors.name = "Please enter name.";
    } else {
      let pattern = /^[a-zA-Z ]+$/;
      if (!pattern.test(input.name)) {
        errors.name = "This field accepts only alphabets.";
      } else if (input.name.trim().length <= 2) {
        errors.name = "Please enter at least 3 characters.";
      }
    }
    if (!input.message) {
      errors.message = "Please enter cover message.";
    }
    setErrors(errors);
    return errors;
  };

  return (
    <div>
      <div className="container">
        <div className="home_contact">
          <div className="row">
            <div className="col-xl-4 col-lg-5  wow slideInLeft" data-wow-delay="0.3s">
              <div className="addres_block">
                <div className="addres_blocks">
                  <h2 className="common_heading ">Contact us</h2>
                  <ul className="rent_news cont_info">
                    <li>
                      <i className="fa fa-location-arrow"></i>
                      <span>
                        Office-09, Floor-10, TDI Business Center, Sector 118,
                        Sahibzada Ajit Singh Nagar, Punjab 140308
                      </span>
                    </li>
                    <li>
                      <a href="tel:+918360249058">
                        <i className="fa fa-phone"></i>
                        <span>+91 8360249058</span>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:hr@ultivic.com">
                        <i className="fa fa-envelope-o"></i>
                        <span>hr@ultivic.com</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="home_form wow slideInRight" data-wow-delay="0.3s">
                <div className="headings_outer ">
                  <div className="mobile_center">
                    <h6 className="sub_heading placement">Get in Touch</h6>
                  </div>
                  <h2 className="common_heading">
                    Let’s create digital revolution together
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Your Name"
                      value={name}
                      onChange={onChange}
                    />
                    {submitted && errors.name && (
                      <div className="inline-errormsgs">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control ${submitted && errors.email && "is-invalid"}`}
                      name="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={onChange}
                    />
                    {submitted && errors.email && (
                      <div className="inline-errormsgs">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      cols="6"
                      rows="6"
                      placeholder="Your Message"
                      value={message}
                      onChange={onChange}
                    ></textarea>
                    {submitted && errors.message && (
                      <div className="inline-errormsgs">{errors.message}</div>
                    )}
                  </div>
                  <div className="global_btn mobile_center">
                    <button type="submit" className="btn btn-sm">Submit</button>
                  </div>
                </form>
                {loading && (
                  <div className="my_loaders">
                    <Oval height={100} width={100} color='grey' ariaLabel='loading' />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homecontact;
