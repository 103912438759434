const pdata = [{
        imgsrc: "/assets/services/planning_web.jpg",
        sub_heading: "PLANNING & STRATEGY",
        common_heading: "We plan the structure and implement it",
        planning_info: "Graphic design still revolves around age-old principles. It is crucial to apply visual hierarchy and page layout techniques to meet users’ specific needs and focus on interactive design for the better user experience.",
        planing_pts01: "Symmetry and Balance ",
        planing_pts02: "Flow",
        planing_pts03: "Repetitions ",
        planing_pts04: "Pattern",
        planing_pts05: "Typography",
        planing_pts06: "Success",
    },
    {
        imgsrc: "/assets/services/MOBILE_ULTIVIC.png",
        sub_heading: "iOS",
        common_heading: " IOS Application Development",
        planning_info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus",
        planing_pts01: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts02: "Ut elit tellus Lorem ipsum dolor sit amet",
        planing_pts03: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts04: "Ut elit tellus Lorem ipsum dolor sit amet",
        planing_pts05: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts06: "Ut elit tellus Lorem ipsum dolor sit amet",

    },
    {
        clases: "androidApp",
        imgsrc: "/assets/services/android.png",
        sub_heading: "Android",
        common_heading: "Android Application Development",
        planning_info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus",
        planing_pts01: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts02: "Ut elit tellus Lorem ipsum dolor sit amet",
        planing_pts03: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts04: "Ut elit tellus Lorem ipsum dolor sit amet",
        planing_pts05: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        planing_pts06: "Ut elit tellus Lorem ipsum dolor sit amet",
    },
];

const tbdata = [{
        textimgsrc: "/assets/services/cms-1.png",
        tech_box_heading: "Native Mobile App Development",
        tech_box_info: "Native mobile app development involves building apps for particular mobile operating systems, and users access them from dedicated app stores.",
    },
    {
        textimgsrc: "/assets/services/shopping-1.png",
        tech_box_heading: "Hybrid Mobile App Development",
        tech_box_info: "Hybrid apps are a blend of both native and web solutions. Where the core of the application is written using web technologies. ",
    },
    {
        textimgsrc: "/assets/services/applications-1.png",
        tech_box_heading: "Progressive Web App Development",
        tech_box_info: "Progressive web app developers optimize web applications since they provide app-like navigation and excellent visual content.",
    },
    {
        textimgsrc: "/assets/services/php.png",
        tech_box_heading: "Wearables and Embedded ",
        tech_box_info: "An embedded system is a microprocessor- or microcontroller-based system of hardware and software designed to perform dedicated functions ",
    },


    {
        textimgsrc: "/assets/services/illustrator.png",
        tech_box_heading: "Illustrator",
        tech_box_info: "Adobe Illustrator is a popular vector graphics editor program for designing that primarily manipulates vector graphic.",
    },
    {
        textimgsrc: "/assets/services/photoshop.png",
        tech_box_heading: "Photoshop",
        tech_box_info: "Adobe Photoshop is considered one of the best raster image editing software, and it is very user-friendly software. ",
    },
    {
        textimgsrc: "/assets/services/experience.png",
        tech_box_heading: "Adobe XD",
        tech_box_info: "Adobe XD is ideal for vector-based UI design, wireframing, interactive design, prototyping, and high-fidelity web/app design, for solo designers or whole teams.",
    },
    {
        textimgsrc: "/assets/services/figma.png",
        tech_box_heading: "Figma",
        tech_box_info: "Figma works on any operating system that runs a web browser. Macs, Windows PCs, Linux machines, and even Chromebooks can be used with Figma. ",
    },
];

const ourteam = [{
    emp_id:1,
    emp_name: "Tarun Gautam",
    emp_designation: "Backend Developer",
    emp_image: "assets/about/tarun.jpg",
    emp_eperinece: "Experience : 3 years",
    emp_expertice: " Skills : Php, Laravel, Java, javascript ",
    emp_about: "Hi! I am Tarun Gautam, working as a backend developer for the last three years. I am learning new technology with challenges by writing high-quality codes. My hobbies are dancing and singing.",
    skills : [
        "Php","mySQL","NodeJs","JavaScript","jQuery","Java","Net Core","laravel","Mongo DB","AWS"
    ]
},
{
    emp_id:2,
    emp_name: "Savita",
    emp_designation: "Java Team Lead",
    emp_image: "assets/about/savita.jpg",
    emp_eperinece: "Experience : 3 years",
    emp_expertice: " Skills : Php, Laravel, Java, javascript",
    emp_about: "I am Savita. Being a Java developer, I design, develop, and manage Java-based applications and software but apart from this other java developer responsibilities include Software maintenance and Optimization, Project Management, Leading and Liaising, and vendor management. I am working as a team lead with the support of my team.",
    skills : [
        "Php","mySQL","NodeJs","JavaScript","jQuery","Java","Html/css"
    ]
},


{
    emp_id:3,
    emp_name: "Vinay",
    emp_designation: "iOS Team Lead",
    emp_image: "assets/about/vinay.jpg",
    emp_about: "I am Vinay, working with Ultivic private limited as an iOS team lead. My duties are understanding apple’s design and principles and interface guidelines. I am good in skills such as Objective –C or Swift and Cocoa touch.",
    emp_eperinece: "Experience : 2 years",
    emp_expertice: " Skills : Swift, Objectice-c",
    skills : [
        "Swift","Firebase","Objective-c","RxSwift","Model View ViewModel","MySQL","Realm","core data","Ble"
    ]

},
{
    emp_id:4,
    emp_name: "Pritpal Singh",
    emp_designation: "UI/UX Team Lead ",
    emp_image: "assets/about/sohi.jpg",
    emp_about: "I'm Pritpal Singh, your guide through the realms of user experience and design. As the UI/UX Team Lead, my mission is to blend creativity with functionality, transforming ideas into seamless, user-centric experiences. Let's embark on this design journey together, where every pixel tells a story and every interaction is crafted with purpose.",
    emp_eperinece: "Experience : 5 years",
    emp_expertice: " Skills : Adobe Creative Suite, Figma, Wordpress, Webflow, Bubble.io",
    skills : [
        "Photoshop","illustrator","Figma","Premiere Pro","Adobe XD","After Effects","Webflow","Wordpress"
    ]
},
{
    emp_id:5,
    emp_name: "Aman Kumar",
    emp_designation: "Bubble.io Developer",
    emp_image: "assets/about/aman.jpg", 
    emp_about: " I'm Aman Kumar, your go-to Bubble.io developer. Passionate about turning visions into interactive realities, I thrive on the endless possibilities that Bubble.io offers. From robust web applications to dynamic prototypes, I'm here to make your digital dreams come true. Let's build something extraordinary together!",
    emp_eperinece: "Experience : 4 years",
    emp_expertice: " Skills : Php, Javascript, Jquery",
    skills : [
        "PHP", "Bubble.io", "Javascript", "Jquery" , "API's", "Database"
    ]

}, 
{
    emp_id:6,
    emp_name: "Deepak",
    emp_designation: "Frontend Developer",
    emp_image: "assets/about/deepak.png",
    emp_about: "I am Deepak Chauhan. I am working as a front-end developer. My team leads helped to improve my skills such as HTML, CSS, JavaScript, and frameworks. As a front-end developer, one should have good communication skills, creativity and problem-solving, and an understanding of learning in a team. I am a kind of creative and health-conscious person. My new hobbies are gyming and traveling to hilly areas.",
    skills : [
        "Html/css","Photoshop","ReactJs","Wordpress","Figma","Shopify"
    ]
},
{
    emp_id:7,
    emp_name: "Hankish Lohia",
    emp_designation: "MERN Stack Developer",
    emp_image: "assets/about/hankish.jpg",
    emp_about :"I'm Hankish Lohia, a MERN stack developer ready to bring your ideas to life in the digital realm. With expertise in MongoDB, Express.js, React.js, and Node.js, I'm here to craft seamless and scalable web applications. Let's create user-centric experiences, optimize performance, and embrace the power of the MERN stack together.",
    emp_eperinece: "Experience : 3 years",
    emp_expertice: " Skills : Photoshop, AI ,XD, Figma",
    skills : [
        "React","Javascript","Jquery","Nodejs","Nextjs","Express.js","MYSQL", "Mongo DB ","Typescript"
    ]

},
{
    emp_name: "Damanpreet",
    emp_id:8,
    emp_designation: "Human Resources",
    emp_image: "assets/about/HR.jpg",
    emp_about: "Hii ! I'm Damanpreet Kaur, Human resources representative with handover experience in managing employee benefits, employee hiring, onboarding and performance management. I am a Reliable and solution-focused professional with excellent interpersonal and rapport- building skills. I am convinced that nothing we do is more important than hiring and developing people.",
    emp_eperinece: "Experience : 2 years",
    emp_expertice: " Skills : Recruiting, Employee relations ,Onboarding",
    skills : [
        "Communication","Decision-making","Finance","Organisational","Business management","Recruiting","Employee relations "
    ]

},
{
    emp_name: "Himanshu Negi",
    emp_id:9,
    emp_designation: "Java Developer",
    emp_image: "assets/about/negi.jpg",
    emp_about: "I Himanshu Negi as Java Developer take the charge of designing, developing, and maintaining Java-based programs. Because Java is so widely used, day-to-day responsibilities vary but can include owning a specific program. My team is quite supportive and lends a helping hand in improving my skills.",
    emp_eperinece: "Experience : 2 years",
    emp_expertice: "Java, react Js, Javascript",
    skills : [
        "Php","mySQL","NodeJs","JavaScript","jQuery","Java","Html/css","React","Angular"
    ]

},
{
    emp_name: "Amir Khan",
    emp_id:10,
    emp_designation: "Full Stack Developer",
    emp_image: "assets/about/amir.jpg",
    emp_about: "I am an Full Stack Developer with 5 years experience but most precious skills that I possess are critical thinking, technical and programming skills. I believe that my strong technical experience and education make me a highly competitive candidate. My key strengths that would support my success. ",
    emp_eperinece: "Experience : 5 years",
    emp_expertice: " Skills : Photoshop, AI ,XD, Figma",
    skills : [
        "HTML","JavaScript","Ajax","PHP","Advanced PHP","Laravel","MySQL","WordPress","Nodejs ","ExpressJS ", "Reactjs", "Nextjs","Vuejs","API Integration","Codeigniter","Symfony","NodeJS","Sequelize","MongoDB"
    ]

},
{
    emp_name: "Mohit Kumar",
    emp_id:11,
    emp_designation: "Tester",
    emp_image: "assets/about/mohit.jpg",
    emp_about: "I'm Quality Assurance Engineer with having 3+ years of experience. I have worked on 80+ projects with knowledge of Functional, Non-Functional, API and Performance Testing.",
    emp_eperinece: "Experience : 3 years",
    emp_expertice: " Skills : Photoshop, AI ,XD, Figma",
    skills : [
        "Android/iOS Tester","Api Tester","Automation","Web App Tester","Performance Testing"
    ]

},
{
    emp_name: "Komal Arora ",
    emp_id:12,
    emp_designation: "Backend Developer",
    emp_image: "assets/about/komal.jpg",
    emp_about: "I am Komal Arora. I have worked on both Backend as well Frontend being part of Application Development team. I have 3+ years experience, In my experience i have developed and maintained projects with Core PHP and Laravel.",
    emp_eperinece: "Experience : 1 years",
    emp_expertice: " Skills : Photoshop, AI ,XD, Figma",
    skills : [
        "PHP","Laravel","Jquery","Javascript","Nodejs","HTML 5", "CSS","Ajax"
    ]

},



]

export default pdata;
export { tbdata, ourteam };