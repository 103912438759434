const content = [{
        imgsrc: "/assets/about/mision.jpg",
        about_exp: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.”",
        sub_heading: "We are global",
        common_heading: "Lorem ipsum sit dolloar",
        about_info: "Nulla tincidunt nulla sit amet sem vehicula, nec malesuada nulla lobortis. Mauris imperdiet nulla nec nibh pharetra a molestie. In accumsan elit est a tincidunt dignissim. Vivamus eu dictum lorem. Aenean vulputate sem a ex tempor ultrices. Sed egestas elit sed dapibus ultrices. Pellentesque at libero libero.",

    },
    {
        imgsrc: "/assets/about/about_main.png",
        about_exp: "“There are three responses to a piece of design – yes, no, and WOW! Wow is the one to aim for.” – Milton Glaser",
        sub_heading: "Graphic Design",
        common_heading: "What is Graphic Design? ",
        about_info: "Graphic design is a craft where professionals create visual content to communicate messages. By applying visual hierarchy and page layout techniques, designers use typography and pictures to meet users’ specific needs and focus on the logic of displaying elements in interactive designs, to optimize the user experience.",

    },
    {
        imgsrc: "/assets/services/web.jpg",
        about_exp: "“We don't just build websites, we build websites that SELLS” ― Christopher Dayagdag",
        sub_heading: "Web Development",
        common_heading: "What is Web Development ?",
        about_info: "Web development is the building and maintenance of websites; it’s the work that happens behind the scenes to make a website look great, work fast and perform well with a seamless user experience. Web developers, or ‘devs’, do this by using a variety of coding languages. The languages they use depends on the types of tasks they are performing and the platforms on which they are working. ",
    },
    {
        imgsrc: "/assets/services/app.png",
        about_exp: "“The future of mobile is the future of online. It is how people access online content now.”",
        sub_heading: "App Development",
        common_heading: "What is App Development? ",
        about_info: "Application development is the process of creating a computer program or a set of programs to perform the different tasks that a business requires which looks great, works fast, and perform well with a seamless user experience. App developers, or ‘devs’, do this by using a variety of coding languages. The languages they use depend on the types of tasks they are performing and the platforms on which they are working.",

    },
    {
        imgsrc: "/assets/services/web.jpg",
        about_exp: "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.”",
        sub_heading: "Welcome to ultivic",
        common_heading: "Connect with our organization",
        about_info: "Application development is the process of creating a computer program or a set of programs to perform the different tasks that a business requires which looks great, works fast and perform well with a seamless user experience. App developers, or ‘devs’, do this by using a variety of coding languages. The languages they use depends on the types of tasks they are performing and the platforms on which they are working.",

    },
];

export default content